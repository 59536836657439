//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    showMore: {
      typeof: Boolean,
      default: true,
    },
  },
};
