//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { imageCutting } from "@/util/util.js";
export default {
  props: {
    item: {
      // 	拍卖状态（，1、待拍卖，2、进行中，3、拍卖结束4、流拍5、撤拍）
      default: () => {
        return {};
      },
      type: Object
    },
    activeIndex: {
      type: Number,
      default: ""
    },
    firstType: {
      type: Number,
      default: ""
    }
  },
  computed: {},
  data() {
    return {
      defaultUrl: require("@/assets/url_empty.png"),
      auctionEndTime: "",
      auctionStartTime: "",
      timeerS: null,
      timeerE: null
    };
  },
  computed: {},
  methods: {
    timeFormat(param) {
      return param < 10 ? "0" + param : param;
    },
    countDown() {
      if (
        this.item.state == 2 &&
        this.item.auctionEndTime > new Date().getTime()
      ) {
        let str = "";
        this.timeerE = setInterval(() => {
          // 获取当前时间，同时得到活动结束时间数组
          let newTime = new Date().getTime();
          // 对结束时间进行处理渲染到页面
          let endTime = this.item.auctionEndTime;
          let obj = null;
          // 如果活动未结束，对时间进行处理
          if (endTime - newTime > 0) {
            let time = (endTime - newTime) / 1000;

            // 获取天、时、分、秒
            let day = parseInt(time / (60 * 60 * 24));
            let hou = parseInt((time % (60 * 60 * 24)) / 3600);
            let min = parseInt(((time % (60 * 60 * 24)) % 3600) / 60);
            let sec = parseInt(((time % (60 * 60 * 24)) % 3600) % 60);
            obj = {
              day: day,
              hou: this.timeFormat(hou),
              min: this.timeFormat(min),
              sec: this.timeFormat(sec)
            };
            if (day > 0) {
              this.auctionEndTime =
                obj.day +
                "天" +
                obj.hou +
                "时" +
                obj.min +
                "分" +
                obj.sec +
                "秒";
            } else if (hou > 0) {
              this.auctionEndTime =
                obj.hou + "时" + obj.min + "分" + obj.sec + "秒";
            } else if (min > 0) {
              this.auctionEndTime = obj.min + "分" + obj.sec + "秒";
            } else if (sec > 0) {
              this.auctionEndTime = obj.sec + "秒";
            }
          } else {
            // 活动已结束，全部设置为'00'
            obj = {
              day: "00",
              hou: "00",
              min: "00",
              sec: "00"
            };
            clearInterval(this.timeerE);
            this.$emit("tiemChange", this.item);
            this.auctionEndTime = "";
          }
        }, 1000);
      }
    },
    countStart() {
      if (
        this.item.state == 1 &&
        this.item.auctionStartTime > new Date().getTime()
      ) {
        let str = "";
        this.timeerS = setInterval(() => {
          // 获取当前时间，同时得到活动结束时间数组
          let newTime = new Date().getTime();
          // 对结束时间进行处理渲染到页面
          let startTime = this.item.auctionStartTime;
          const date = new Date(startTime);
          let obj = null;
          let obj2 = {
            day: "天",
            hou: "时",
            min: "分",
            sec: "秒"
          };
          // 如果活动未结束，对时间进行处理
          if (startTime - newTime > 0) {
            let time = (startTime - newTime) / 1000;
            // 获取天、时、分、秒
            let day = parseInt(time / (60 * 60 * 24));
            let hou = parseInt((time % (60 * 60 * 24)) / 3600);
            let min = parseInt(((time % (60 * 60 * 24)) % 3600) / 60);
            let sec = parseInt(((time % (60 * 60 * 24)) % 3600) % 60);
            obj = {
              day: day,
              hou: this.timeFormat(hou),
              min: this.timeFormat(min),
              sec: this.timeFormat(sec)
            };
            let a = +obj.day != 0 ? obj.day + "天" : "";
            let b = obj.hou != 0 ? obj.hou + "时" : "";
            let c = +obj.min != 0 ? obj.min + "分" : "";
            let d = +obj.sec != 0 ? obj.sec + "秒" : "";
            this.auctionStartTime = a + b + c + d;
          } else {
            // 活动已结束，全部设置为'00'
            obj = {
              day: "00",
              hou: "00",
              min: "00",
              sec: "00"
            };
            clearInterval(this.timeerS);
            this.$emit("tiemChange", this.item);
            this.auctionStartTime = "";
          }
        }, 1000);
      }
    },
    imageCutting: imageCutting
  },
  filters: {
    moneyToCurrency(num) {
      if (num) {
        let n = Number(num).toFixed(2);
        // let re = /(\d{1,3})(?=(\d{3})+(?:$|\.))/g;
        let re = new RegExp("(\d{1,3})(?=(\d{3})+(?:$|\.))", "g");
        let n1 = n.replace(re, "$1,");
        return n1;
      } else {
        return "0.00";
      }
    },
    setPriceName(item) {
      // 1、待拍卖，2、进行中，3、拍卖结束4、流拍5、撤拍
      let { state, version } = item;
      switch (state) {
        case 1:
          return "起拍价";
          break;
        case 2:
          if (version > 0) {
            return "当前价";
          } else {
            return "起拍价";
          }
          break;
        case 3:
          return "成交价";
          break;
        case 4:
          return "起拍价";
          break;
        case 5:
          return "起拍价";
          break;
        default:
          break;
      }
    },
    datanumber2datetime(datetime, fmtdata) {
      if (!datetime) return "/";
      function padLeftZero(str) {
        return ("00" + str).substr(str.length);
      }
      if (datetime === "" || datetime === null) {
        return "";
      }
      const date = new Date(datetime);
      let fmt = fmtdata && fmtdata !== "" ? fmtdata : "yyyy-MM-dd hh:mm:ss";
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      const o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds()
      };
      for (const k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          const str = o[k] + "";
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : padLeftZero(str)
          );
        }
      }
      return fmt;
    }
  },
  created() {
    if (this.item.state === 1) {
      // this.countDown();
      this.countStart();
    }
    if (this.item.state === 2) {
      this.test = 123;
      this.countDown();
    }
  },
  beforeDestroy() {
    // 组件销毁前 清楚定时器
    clearInterval(this.timeerS);
    clearInterval(this.timeerE);
  }
};
