import { render, staticRenderFns } from "./comSmallTBCardSke.vue?vue&type=template&id=61e10d3c&scoped=true&"
import script from "./comSmallTBCardSke.vue?vue&type=script&lang=js&"
export * from "./comSmallTBCardSke.vue?vue&type=script&lang=js&"
import style0 from "./comSmallTBCardSke.vue?vue&type=style&index=0&id=61e10d3c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61e10d3c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Template: require('/devcloud/slavespace/slave1-new/workspace/j_V6eVlOYm/src/components/FilterSearch/template/index.js').default})
