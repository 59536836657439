//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "",
  components: {
    ListEmpty: () => import("../components/EmptyData/index")
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    tabindex: {
      type: Number,
      default: () => 0
    }
  },
  data() {
    return {
      inquiryMethods: { 1: "市场询价", 2: "成员询价", 3: "会员询价" },
      loadType: { 1: "装车供方承担", 2: "装车需方承担", 3: "待定" }, // 装车要求
      deliveryType: { 1: "需方提货", 2: "供应商送货", 3: "待定" },
      unloadType: { 1: "卸车供方承担", 2: "卸车需方承担", 3: "待定" }
    };
  },
  computed: {
    demandList() {
      let demandList = this.item.demandCommodities;
      return demandList.splice(0, 3);
    }
  },
  watch: {},
  created() { },
  mounted() { },
  methods: {},
  filters: {
    filterMoney(val) {
      if (val >= 10000) {
        return (val / 10000).toString().match(/^\d+(?:\.\d{0,2})?/) + "万元";
      } else {
        return val.toFixed(2) + "元";
      }
    },
    /**
     * 大数值转换为万，亿函数
     * @param {Number} 大数
     * @param {Number} 保留几位小数
     */
    numConversion(num, point = 2) {
      if (!num) return 0;
      const numStr = num.toString().split(".")[0]; // 去掉小数点后的数值字符串
      const numLen = numStr.length;
      if (numLen < 6) {
        return numStr;
      } else if (numLen >= 6 && numLen <= 8) {
        const decimal = numStr.substring(numLen - 4, numLen - 4 + point);
        const res = parseInt(num / 10000) + "." + decimal + "万";
        return res;
      } else if (numLen > 8) {
        const decimal = numStr.substring(numLen - 8, numLen - 8 + point);
        const res = parseInt(num / 100000000) + "." + decimal + "亿";
        return res;
      }
    },
    datanumber2datetime(datetime, fmtdata) {
      if (!datetime) return "/";
      function padLeftZero(str) {
        return ("00" + str).substr(str.length);
      }
      if (datetime === "" || datetime === null) {
        return "";
      }
      const date = new Date(datetime);
      let fmt = fmtdata && fmtdata !== "" ? fmtdata : "yyyy-MM-dd hh:mm:ss";
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      const o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds()
      };
      for (const k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          const str = o[k] + "";
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : padLeftZero(str)
          );
        }
      }
      return fmt;
    }
  }
};
