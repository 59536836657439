//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "",
  components: {
    ListEmpty: () => import("../components/EmptyData/index"),
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  computed: {
    noDataFlag() {
      return [6, 7, 8, 9, 10, 13, 20].includes(this.item.type);
    },
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    getgoodsFilter(row, type) {
      if (row.length === 0) return "";
      const item = row.find((x) => x.name === type.name);
      return (item && item.value) || "";
    },
  },
  filters: {
    setState(val) {
        // STATE_1(1, "草稿"),
        // STATE_20(20, "审批中"),
        // STATE_2(2, "竞价中"),
        // STATE_5(5, "谈判中"),
        // STATE_3(3, "已结束"),
        // STATE_21(21, "审批不通过"),
        // STATE_4(4, "废标");
        let obj = {
          2: '竞价中',
          5: '谈判中',
          3: '已结束'
        }
      return obj[val];
    },
    filterMoney(val) {
      if (val >= 10000) {
        return (val / 10000).toString().match(/^\d+(?:\.\d{0,2})?/) + "万元";
      } else if (val == 0){
        return "按清单组价"
      }else {
        return val + "元";
      }
    },
    /**
     * 大数值转换为万，亿函数
     * @param {Number} 大数
     * @param {Number} 保留几位小数
     */
    numConversion(num, point = 2) {
      if (!num) return "/";
      const numStr = num.toString().split(".")[0]; // 去掉小数点后的数值字符串
      const numLen = numStr.length;
      if (numLen < 6) {
        return numStr;
      } else if (numLen >= 6 && numLen <= 8) {
        const decimal = numStr.substring(numLen - 4, numLen - 4 + point);
        const res = parseInt(num / 10000) + "." + decimal + "万";
        return res;
      } else if (numLen > 8) {
        const decimal = numStr.substring(numLen - 8, numLen - 8 + point);
        const res = parseInt(num / 100000000) + "." + decimal + "亿";
        return res;
      }
    },
    datanumber2datetime(datetime, fmtdata) {
      function padLeftZero(str) {
        return ("00" + str).substr(str.length);
      }
      
      if (datetime === "" || datetime === null) {
        return "";
      }
      datetime = +datetime
      const date = new Date(datetime);
      let fmt = fmtdata && fmtdata !== "" ? fmtdata : "yyyy-MM-dd hh:mm:ss";
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      const o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
      };
      for (const k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          const str = o[k] + "";
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : padLeftZero(str)
          );
        }
      }
      return fmt;
    },
  },
};
