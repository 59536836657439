//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { apiHome } from "../../../../src/api/apiHome.js";
// import Api from "@/api/apiBusiness";

export default {
  components: {
    // Purchaselist: () => import("../../Opportnity/Purchaselist/index.vue"),
    BidItem: () => import("./BidItem"),
    ListCommonContent: () => import("@/pages/Home/components/tender/components/listCommonContent/index.vue"),
    // jPop: () => import("@/components/jPop"),
  },
  data() {
    return {
      jShow: -1,
      dialogShow: true,
      detailData:{},
    };
  },
  props: {
    item: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },
  filters:{
     dealStatus(status) {
      // 招标状态
      let str = "";
      switch (status) {
        case 1:
          str = "待招标";
          break;
        case 2:
          str = "招标中";
          break;
        case 3:
          str = "评标中";
          break;
        case 4:
          str = "招标结束";
          break;
        case 5:
          str = "废标";
          break;
        case 6:
          str = "招标中";
          break;
        case 7:
          str = "流标";
          break;
        case 8:
          str = "待报名";
          break;
        case 9:
          str = "报名中";
          break;
        case 10:
          str = "资格审查中";
          break;
        default:
          break;
      }
      return str;
    },
    datanumber2datetime(datetime, fmtdata) {
      if (!datetime) return "/";
      function padLeftZero(str) {
        return ("00" + str).substr(str.length);
      }
      if (datetime === "" || datetime === null) {
        return "";
      }
      const date = new Date(datetime);
      let fmt = fmtdata && fmtdata !== "" ? fmtdata : "yyyy-MM-dd hh:mm:ss";
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      const o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds()
      };
      for (const k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          const str = o[k] + "";
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : padLeftZero(str)
          );
        }
      }
      return fmt;
    }
  },
  computed: {
    itemData: function () {
      let arr = [];
      this.item.goodsList &&
        this.item.goodsList.map((list, index) => {
          let objData = {};
          list &&
            list.map((itt, i) => {
              objData[itt.name] = itt.value;
            });
          arr.push(objData);
        });
      return arr;
    },
    typeNum: function () {
      if (this.item.businessType === 2) {
        return 0;
      } else if (this.item.businessType === 3) {
        return 5;
      } else {
        return -1;
      }
    },
  },
  methods: {
    jMover(i) {
      this.jShow = i;
    },
    // 函询
    inByLetDialogClick(e, s, obj) {
      if (s == 3) {
        this.$emit("deByLetDialogClick", e, s, obj);
      } else {
        this.$emit("inByLetDialogClick", e, s, obj);
      }
    },
    goNewDetail(no, obj = {}) {
      return
      if (obj.dataSources === 2) {
        let url = `/tenderDetails?id=${obj.id}`;
        window.open(url);
      } else {
        if (obj.businessType === 2) {
          window.open(
            "/CompeteBiddingDetail?no=" + obj.id + "&typeName=" + obj.typeName
          );
        } else if (obj.businessType === 3) {
          // let url = `/demandEsDetail?id=${obj.id}&type=${obj.type}`;
          // window.open(url);
          if (obj.type && obj.type == 3) {
            window.open("/companyDemandDetail" + "?id=" + obj.id);
          } else if (obj.type && obj.type == 2) {
            window.open("/companyDemandDetail" + "?no=" + obj.id + "&&type=10");
          } else if (obj.type && obj.type == 19) {
            window.open("/companyDemandDetail" + "?no=" + obj.id + "&&type=9");
          } else if (obj.type && obj.type == 20) {
            window.open("/companyDemandDetail" + "?no=" + obj.id + "&&type=8");
          } else if (obj.type) {
            window.open("/companyDemandDetail" + "?no=" + obj.id);
          }
        } else if (obj.businessType === 4) {
          window.open(`/hotTenderAdvanceLaborDetail?no=${obj.id}`);
        } else {
          if (this.$route.path === "/direct-jb") {
            window.open(`/CompeteBiddingDetail?no=${no}`);
          } else {
            if (obj.state === 8) {
              window.open("/biddingDetail?id=" + obj.id);
            } else {
              window.open(`/hotTenderMachineLaborDetail?no=${no}`);
            }
          }
        }
      }
    },
    // 商机数据的关注
    getMoney(val) {
      if (val >= 10000) {
        return (val / 10000).toString().match(/^\d+(?:\.\d{0,2})?/) + "万元";
      } else {
        return val ? `${val}元` : "按清单组价";
      }
    },
    getBackground(status) {
      let str = "";
      switch (status) {
        case 1:
          str = "#F7AB01";
          break;
        case 2:
          str = "#0286DF";
          break;
        case 3:
          str = "#78C06E";
          break;
        case 4:
          str = "#969696";
          break;
        case 5:
          str = "#969696";
          break;
        case 6:
          str = "#0286DF";
          break;
        case 8:
          str = "#409ee9";
          break;
        case 9:
          str = "#78C06E";
          break;
        case 10:
          str = "#F7AB01";
          break;
        default:
          str = "#969696";
          break;
      }
      return str;
    },
  },
  filters: {
   
  },
  created() {},
};
