//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ornumEnum } from "../constants";
export default {
  name: "",
  components: {},
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      ornumEnum: ornumEnum,
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
  filters: {
    //获取当前时间距离截止时间的倒计时
    //参数为截止时间
    leftTimer(time) {
      const leftTime = time - new Date(); //计算剩余毫秒数
      let days = parseInt(leftTime / 1000 / 60 / 60 / 24, 10); //计算剩余天数
      let hours = parseInt((leftTime / 1000 / 60 / 60) % 24, 10); //计算剩余小时数
      let minutes = parseInt((leftTime / 1000 / 60) % 60, 10); //计算剩分钟数
      let seconds = parseInt((leftTime / 1000) % 60, 10); //计算剩余秒数
      days = checkTime(days).toString();
      hours = checkTime(hours).toString();
      minutes = checkTime(minutes).toString();
      seconds = checkTime(seconds).toString();
      function checkTime(i) {
        if (i < 10) {
          i = "0" + i;
        }
        return i;
      }
      return days + " 天 " + hours + " 小时 ";
    },
    /**
     * 大数值转换为万，亿函数
     * @param {Number} 大数
     * @param {Number} 保留几位小数
     */
    numConversion(num, point = 2) {
      if (!num) return "/";
      const numStr = num.toString().split(".")[0]; // 去掉小数点后的数值字符串
      const numLen = numStr.length;
      if (numLen < 6) {
        return numStr;
      } else if (numLen >= 6 && numLen <= 8) {
        const decimal = numStr.substring(numLen - 4, numLen - 4 + point);
        const res = parseInt(num / 10000) + "." + decimal + "万";
        return res;
      } else if (numLen > 8) {
        const decimal = numStr.substring(numLen - 8, numLen - 8 + point);
        const res = parseInt(num / 100000000) + "." + decimal + "亿";
        return res;
      }
    },
    datanumber2datetime(datetime, fmtdata) {
      function padLeftZero(str) {
        return ("00" + str).substr(str.length);
      }
      if (datetime === "" || datetime === null) {
        return "";
      }
      const date = new Date(datetime);
      let fmt = fmtdata && fmtdata !== "" ? fmtdata : "yyyy-MM-dd hh:mm:ss";
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      const o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
      };
      for (const k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          const str = o[k] + "";
          fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str));
        }
      }
      return fmt;
    },
  },
};
