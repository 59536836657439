//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from "moment";
export default {
  name: "",
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    tabindex: {
      type: Number,
      default: () => 0
    },
    type: {
      type: [String, Number],
      default: () => ''
    }
  },
  data() {
    return {
      inquiryMethods: { 1: "市场询价", 2: "成员询价", 3: "会员询价" },
      labourMethods:{ 1:'市场询价', 2:'成员询价', 3:'会员询价' },
      loadType: { 1: "供方承担", 2: "需方承担", 3: "待定" },
      typeList: {
        1: "机械租赁",
        2: "设备租赁",
        3: "车辆租赁",
        4: "房产租赁",
        5: "土地租赁",
        6: "工厂租赁",
        7: "农机租赁",
        10: "物流货运",
        11: "劳务用工",
        9:'其他'
      },
      customHeaderEnum: {
        10: [
          {label: '货物类型', field: 'firstTypeName'},
          {label: '装货地', field: 'loadAddress'},
          {label: '收货地', field: 'destAddress'},
          {label: '需求时间', field: 'firstTypeName1'},
          {label: '预估运输量', field: 'firstTypeName2'},
        ],
        11: [
          {label: '工种分类', field: 'threeTypeName'},
          {label: '用工人数', field: 'loadAddress'},
          {label: '工作内容', field: 'destAddress'},
          {label: '进场时间', field: 'firstTypeName1'},
          {label: '工期', field: 'firstTypeName2'},
        ]
      }
    };
  },
  components: {
    "no-data": () => import("@/pages/Home/components/noData")
  },
  computed: {
    labelFilter() {
      if(this.type == 10 || this.type == 9) { // 物流 其他
        return '收货地址：'
      }
      if(this.type == 11) { // 劳务
        return '工作地址：'
      }
      return '工作地：'
    },
    datalist() {
      let item = JSON.parse(JSON.stringify(this.item));
      let goodsHeader = item.goodsHeader;
      let goodsList = item.goodsList || item.informationVOList || item.inventoryList || [];
      // console.log("goodsListgoodsList", goodsList);
      if (!goodsList.length) {
        return [];
      } else {
        let datalist = [];
        datalist = goodsList.map(item => {
          let arr = [];
          let obj = {};
          for (let i = 0; i < goodsHeader.length; i++) {
            let key = goodsHeader[i]["name"];
            if (this.type == 10) {
              if (key === "logisticsEndTime") {
                arr[i] = moment(item[key]).format("YYYY-MM-DD HH:mm:ss");
              } else {
                arr[i] = item[key];
              }
            } else if (this.type == 11) {
              switch (key) {
                case "mobilizationTime":
                  arr[i] = moment(item[key]).format("YYYY-MM-DD HH:mm:ss");
                  break;
                case "workersNumber":
                  arr[i] = item[key] + "人";
                  break;
                case "duration":
                  arr[i] = item[key] + "天";
                  break;
                default:
                  arr[i] = item[key];
                  break;
              }
            } else if(this.type == 9){
              console.log("item", item)
              console.log("key", key)
              switch (key) {
                case "demandTime":
                  arr[i] = moment(item[key]).format("YYYY-MM-DD");
                  break;
                case "amountBudget":
                  arr[i] = item[key] + (item['unit'] || '');
                  break;
                default:
                  arr[i] = key
                  break;
              }
            } else {
              for (let j = 0; j < item.length; j++) {
                if (item[j]["name"] == key) {
                  arr[i] = item[j]["value"];
                }
              }
            }
          }
          obj["list"] = arr;
          return obj;
        });
        return datalist.splice(0, 3);
      }
    }
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    getgoodsFilter(row, type) {
      if (row.length === 0) return "";
      const item = row.find(x => x.name === type);
      return (item && item.value) || "";
    }
  },
  filters: {
    filterMoney(val) {
      if (val >= 10000) {
        return (val / 10000).toString().match(/^\d+(?:\.\d{0,2})?/) + "万元";
      } else {
        return val.toFixed(2) + "元";
      }
    },
    /**
     * 大数值转换为万，亿函数
     * @param {Number} 大数
     * @param {Number} 保留几位小数
     */
    numConversion(num, point = 2) {
      if (!num) return 0;
      const numStr = num.toString().split(".")[0]; // 去掉小数点后的数值字符串
      const numLen = numStr.length;
      if (numLen < 6) {
        return numStr;
      } else if (numLen >= 6 && numLen <= 8) {
        const decimal = numStr.substring(numLen - 4, numLen - 4 + point);
        const res = parseInt(num / 10000) + "." + decimal + "万";
        return res;
      } else if (numLen > 8) {
        const decimal = numStr.substring(numLen - 8, numLen - 8 + point);
        const res = parseInt(num / 100000000) + "." + decimal + "亿";
        return res;
      }
    },
    datanumber2datetime(datetime, fmtdata) {
      if (!datetime) return "";
      function padLeftZero(str) {
        return ("00" + str).substr(str.length);
      }
      if (datetime === "" || datetime === null) {
        return "";
      }
      const date = new Date(datetime);
      let fmt = fmtdata && fmtdata !== "" ? fmtdata : "yyyy-MM-dd hh:mm:ss";
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      const o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds()
      };
      for (const k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          const str = o[k] + "";
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : padLeftZero(str)
          );
        }
      }
      return fmt;
    }
  }
};
